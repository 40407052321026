.contactContainer {
  padding-top: 130px;
}

.inputLabel {
  color: #40547b;
  font-weight: 550;
}

.contactFormContainer {
  margin: 10px 0 0 !important;

  padding-top: 10px;

  width: 100% !important;
}

.contactFormBlock input,
.contactFormBlock textarea {
  width: 100%;
  background: #ffffff;
  border: 1.28617px solid #d1cdcd;
  border-radius: 5.14469px;
  padding: 0 15px;
}

.contactFormInnerContainer {
  border: 2px solid #fc644c;
  padding: 20px 30px;
  border-radius: 10px;
}

.contactFormBlock input {
  height: 45px;
}

.contactFormBlock input::placeholder,
.contactFormBlock textarea::placeholder {
  color: #5a5a5a;
  font-weight: 600;
}

.contactFormBlock .contactField {
  margin-bottom: 20px;
}

.gridFormFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.contactFormBlock .gridFormFields input {
  margin-bottom: 0px;
}

.contactFormBlock textarea {
  padding-top: 10px;
}

.contactSubmitContainer button {
  width: 100%;
  background: #40547b;
  border-radius: 6px;
  color: #fff;
  border: 2px solid #40547b;
  padding: 6px 0px;
  font-size: 20px;
}

.contactSubmitContainer button:hover {
  background: #fff;
  color: #40547b;
  font-weight: 600;
}

.contactFormCol1 {
  padding-left: 0px !important;
  padding-top: 10px !important;
}

.contactFormCol2 {
   display: flex;
   align-items: center;
   justify-content: center
}

.contactFormCol2 img {
  max-width: 410px;
}

.contactFormBlock input:focus,
.contactFormBlock textarea:focus {
  outline: #f69033;
  border-color: #f69033;
}

.contactFormCol1 h5 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 950px) {
  .contactContainer {
    padding-bottom: 50px;
  }
}

.sendGifContainer {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.sendGifContainer img:nth-child(2) {
  max-width: 20%;
  position: absolute;
  bottom: -16px;
  left: 76px;
}
.sendGifContainer img:nth-child(1) {
  max-width: 80%;
}

.formSubmittedContainer h4,
.formSubmittedContainer p {
  text-align: center;
}

.contactFormCol1 {
  align-self: center;
}
