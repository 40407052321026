.aboutContainer h1 {
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #A7A7A7;
}

.aboutContainer {
    padding-top: 100px;
}

@media only screen and (max-width: 950px) {
    .aboutContainer {
        padding-top: 80px;
    }
}