@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;800&family=Raleway:wght@500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}

.classContainer {
  padding: 50px 100px;
}

.flexContainer {
  display: flex;
  align-items: center;
}

button,
input,
select {
  font-family: "Mulish" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: "Mulish" !important;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 30px;
}

.majorHeading {
  /* font-size: calc(1.3rem + 1vw); */
  font-size: calc(1rem + 1vw);
  font-weight: bold;
  color: #40547b;
  text-align: left !important;
}

p {
  font-size: 18px;
}

.toggleBtn .Mui-checked {
  color: #00c247 !important;
}

.toggleBtn .Mui-checked + .MuiSwitch-track {
  background-color: #00c247 !important;
}
.orangeIcon {
  fill: #fe7e44 !important;
}
.addQuesMenu:hover,.addQuesMenu:focus,.addQuesMenu:active,.addQuesMenu.Mui-selected:hover,.Mui-selected
{
  background-color: rgba(255, 220, 204, 0.5) !important;
}
@media only screen and (max-width: 850px) {
  .classContainer {
    padding: 0 35px;
  }

  .btnContainer {
    text-align: center;
  }
  #demo-select-small {
    white-space: inherit;
  }
}

@media only screen and (max-width: 550px) {
  .majorHeading {
    margin-bottom: 10px;
  }
  .classContainer {
    padding: 0 15px 20px 15px;
  }

  .breadCrumbBlock {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ribbonStrips {
    max-width: 54% !important;
    top: 130px !important;
  }

  .ribbonStrips2 {
    top: 170px !important;
  }
}

.ribbonStrips {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.orangeStrips span {
  background: #fc644c;
}

.orangeStrips span::before {
  border-left: 3px solid #fc644c;
  border-top: 3px solid #fc644c;
}

.orangeStrips span::after {
  border-right: 3px solid #fc644c;
  border-top: 3px solid #fc644c;
}

.greyStrips span {
  background: #bdbdbd;
}

.greyStrips span::before {
  border-left: 3px solid #bdbdbd;
  border-top: 3px solid #bdbdbd;
}

.greyStrips span::after {
  border-right: 3px solid #bdbdbd;
  border-top: 3px solid #bdbdbd;
}

.ribbonStrips2 {
  top: 125px;
}

.analyticsDialog .MuiDialog-paper {
  max-width: 450px !important;
  border-radius: 8px;
}
.ribbonStrips3 {
  top: 175px;
}

.downloadTranscript {
  background: #40547b;
  color: #fff;
  border: 2px solid #40547b !important;
  border-radius: 6px;
}

#use-autocomplete-demo-listbox li {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

#use-autocomplete-demo-listbox li img {
  max-width: 40px;
}

.noResponseImgContainer {
  text-align: center;
}

.noResponseImgContainer img {
  max-width: 120px;
}

@media only screen and (max-width: 850px) {
  .classContainer {
    padding: 0 35px;
  }

  .btnContainer {
    text-align: center;
  }
  #demo-select-small {
    white-space: inherit;
  }
}

@media only screen and (max-width: 550px) {
  .majorHeading {
    margin-bottom: 10px;
  }
  .classContainer {
    padding: 0 15px 20px 15px;
  }

  .breadCrumbBlock {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ribbonStrips {
    max-width: 54% !important;
    /* top: 0px !important; */
  }

  .ribbonStrips2 {
    top: 170px !important;
  }
}

.scale-in-hor-center {
  -webkit-animation: scale-in-hor-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-hor-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

.analysisQuestionContainer .surveyAnalysisReusableBlockHeadingRow {
  justify-content: flex-start !important;
}

.sharingSectionBtn {
  justify-content: center;
  margin-top: 20px;
}

.sharingSectionBtn > div:nth-child(2),
.sharingSectionBtn > div:nth-child(3) {
  display: none;
}
