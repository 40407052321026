.profileBtn {
  visibility: hidden !important;
  height: 100%;
  width: 100%;

  position: absolute !important;
  background-color: black;
}
.proIconSpan {
  margin-right: 10px;
  background: #40547b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
}
.spanProMenu {
  margin-right: 10px;
  background: #40547b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 550px) {
  .spanProMenu {
    margin-right: 0px;
  }
  .profileFirstName,
  .profileLastName {
    display: none;
  }
  .proIconSpan {
    margin-right: 0;
  }
}
