.loginContainer {
  padding-top: 120px;
  padding-bottom: 70px;
  width: 100% !important;
  margin: 0 !important;
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
}
.insideLogin {
  background: #ffffff;
  background-color: #ffffff;
  overflow: hidden;
}
.insideLogin {
  background: #fff;
  border-radius: 25px;
}
.MuiGrid-root.loginCol1.MuiGrid-item {
  text-align: center;
  align-self: flex-start;
  border-radius: 40px;
}
.loginCol1 {
  text-align: center;
  border-radius: 40px;
  align-self: center;
}
.loginCol2 {
  background: #e0edfe;
  padding: 50px;
}
.loginPageMain
  > .MuiPaper-root.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.manualHeadingLine {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.googleContainer button {
  padding: 0px;
  width: 100%;
  background-color: #fff !important;
  color: #000 !important;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid #f69033;
  border-radius: 6px !important;
  padding: 5px 0px !important;
  cursor: pointer !important;
}

.loginPageMain .MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary {
  background-color: transparent !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.googleContainer button > span {
  column-gap: 15px;
}

.googleContainer button:hover {
  background-color: #f690339c !important;
  color: #fff !important;
}

.googleContainer img {
  max-width: 7%;
}

.separatingContainer {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: center;
}

.separatingContainer hr {
  width: 100%;
  color: #8b8b8b;
  height: 2px;
}

.separatingContainer p {
  width: auto;
  padding: 0 40px;
  color: #8b8b8b;
  font-size: 16px;
}

.forgotBlock input {
  width: 20px;
  height: 16px;
  margin: 0 10px 0 0;
}

.forgotBlock p {
  color: #121212;
  font-weight: 600;
}

.forgotBlock a {
  color: #0846d5 !important;
  font-weight: 600;
}

.loginBlock {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 10px 40px;
}

.loginTitle {
  color: #000 !important;
  text-align: center !important;
}

.loginCol2 {
  text-align: right;
}

.loginCol2 img {
  /* max-width: 535px; */
  width: 100%;
}

.singUpImg {
  align-self: flex-start;
}

.loginFormBlock input {
  margin-bottom: 10px;
}

.loginFormBlock button {
  padding: 5px 0px;
}

.forgotBlock {
  margin-bottom: 20px !important;
  flex-wrap: wrap;
}

.forgotBlock input {
  margin-bottom: 0px;
}

.selectPricePlan {
  width: 100%;
  background: #ffffff;
  border: 0.906097px solid #d1cdcd;
  box-sizing: border-box;
  border-radius: 3.62439px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0px 20px;
}

.selectPricePlan:focus-within {
  outline: none;
}

.pricePlanContainer {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.pricePlanContainer p {
  font-size: 20px;
}

.pricePlanContainer p span {
  color: #000;
}

.pricePlanContainer button {
  padding: 5px 20px;
  font-size: 19px;
  width: auto;
}

.upgradeBlock {
  flex: 1;
  text-align: right;
}

.greenColor b {
  color: #00c85c;
}

.blueColor b {
  color: #40547b;
}

.upgradeBlock .greenButton {
  border: 2px solid #00c85c;
  background: #00c85c;
  color: #fff;
  font-weight: 600;
}

.greenButton:hover {
  background: #fff;
  color: #00c85c;
}

.upgradeBlock .blueButton {
  border: 2px solid #40547b;
  background: #40547b;
  color: #fff;
  font-weight: 600;
}

.blueButton:hover {
  background: #fff;
  color: #40547b;
}
HEAD .ipDiv .footerInputContainer {
  height: 100%;
}
.loginFormBlock input:focus {
  outline: #f69033;
  border-color: #f69033;
  background: #fff !important;
}

@media only screen and (max-width: 980px) {
  .loginBlock {
    max-width: 100%;
  }

  .loginCol2 img {
    max-width: 100%;
    margin-top: 40px;
  }
}

.loginFormBlock input:focus {
  outline: #f69033;
  border-color: #f69033;
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .loginContainer p {
    text-align: center !important;
  }
  .pricePlanContainer {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 12px;
  }
  .loginBlock {
    padding: 10px 20px;
  }
  .googleContainer button > span,
  .upgradeBlock .greenButton {
    font-size: 13px;
  }
  .pricePlanContainer p {
    font-size: 20px;
  }
  .loginBlock p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .separatingContainer p {
    padding: 0 10px;
  }
  .googleContainer button > span {
    column-gap: 6px;
  }
  .styles_separatingContainer__dIdu2 {
    margin: 0;
  }
}
@media only screen and (max-width: 500px) {
  .loginContainer {
    padding-bottom: 20px;
    padding-top: 80px;
    padding-left: 0;
    padding-right: 0;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item.singUpImg {
    padding-bottom: 0;
    padding-top: 0;
  }
  .loginCol2 img {
    margin-top: 0;
  }
}

.fixedTrigger img {
  position: fixed;
  top: 0;
  width: 543px;
}
