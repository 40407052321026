.headerContainer {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  );
  z-index: 1201 !important;
}

.openWidth {
  width: calc(100% - 200px) !important;
}

.arrow {
  color: green;
}
.arrowright {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  justify-content: flex-end;
  align-items: center;
}
.arrowleft {
  position: relative;
  height: 10px;
  width: 10px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(-45deg);
  left: 2px;
  top: 0px;
}
.headerBlock {
  justify-content: space-between;
}

.logoContainer {
  width: 100%;
  /* max-width: 17%; */
  max-width: 14%;
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.searchContainer {
  width: 100%;
}

.searchContainer input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}

.searchBlock {
  width: 100%;
  max-width: 50%;
  color: black;
}

.searchBarBlock {
  background: #fff;
  max-width: 60%;
  margin: 0 auto !important;
}

.profileContainer {
  text-align: right;
  width: 100%;
  display: flex;
  min-width: 25%;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}
.helpNewClass{
  max-width: max-content;
}

.profileContainer a {
  position: relative;
}

/* .profileContainer a:after {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  right: -25%;
} */
.helpNewClass a:first-child {
  padding-right: 16px;
}
.marginlr a{
  margin: 0 10px;
  padding-right: 0 !important;
}
.burgerMenu svg {
  margin-left: 0px !important;
}

.dashboardLinkList {
  padding-right: 4px !important;
  margin-top: 50px !important;
  padding-top: 15px;
}

.dashboardLinkList a {
  padding: 8px 20px !important;
  margin-bottom: 5px !important;
  display: flex;
}

.dashboardLinkList .dashboardList:hover {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  ) !important;
}
.activeNav {
  background: linear-gradient(
    270.27deg,
    #fc644c -10.48%,
    #ff7e45 82.09%,
    #e9ae16 123.16%
  ) !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.dashboardLinkList .dashboardList:hover {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.dashboardLinkList .dashboardList:hover svg,
.activeNav svg {
  fill: #fff;
}

.activeNav span,
.dashboardLinkList .dashboardList:hover span {
  color: #fff !important;
}

.dashboardLinkList span {
  font-size: 16px;
  font-weight: 700;
  color: #3c4043;
}


@media only screen and (max-width: 950px) {
  .logoContainer {
    max-width: 25%;
  }

  .searchBlock {
    max-width: 54%;
  }
}

@media only screen and (max-width: 550px) {
  .headerBlock {
    justify-content: space-between !important;
  }

  .searchBlock {
    display: none;
  }

  .headerLogo {
    display: none !important;
  }
}

@media only screen and (max-width: 950px) {
  .profileContainer {
    max-width: 23%;
  }
}

@media only screen and (min-width: 550px) {
  .mobileHeaderLogo {
    display: none;
  }
}

.hideLogo .burgerMenu {
  display: none;
}

.menuIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}


.menuSmallText {
  font-size: 10px !important;
}

.burgerMenu {
  margin: 0 !important;
}

.searchResultContainer li:hover {
  background-color: #2977f5;
  color: white;
}
.menuIconContainer .MuiSvgIcon-root,.menuIconContainer svg{
  color:#121212;
}