@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;800&family=Raleway:wght@500;600;700;800;900&display=swap");

.FooterBg {
  width: 100%;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background: url("../../assets/images/Footer.png");
  padding: 50px 50px 10px 50px;
}
.footerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center;
}

.footerQuoteMessage {
  color: white;
}
.footerlogo {
  width: 32%;
  height: 100%;
  margin: 0px auto;
  text-align: center;
}
.footerlogo img {
  width: 75%;
}
.errMsgP {
  color: red;
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
}
.relativeFooter {
  position: relative;
}
.FooterInput {
  width: 50%;
  position: relative;
  margin: 30px auto 0 auto;
}
.FooterInput input {
  border: none;
  outline: none;
  width: 100%;
  height: 45px;
  padding: 10px;
  padding-left: 30px;
  border-radius: 30px;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.FooterInput button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 2px;
  width: 35%;
  padding: 10px;
  border-radius: 30px;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  background-color: #e24c2d;
  color: #fff;
  border: none;
}
.FooterSocialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin: 20px auto 5px auto;
}
.FooterSocialIcon span {
  background: white;
  color: #313131;
  border-radius: 50%;
  min-width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}
.FooterSocialIcon span i {
  font-size: 18px;
}
.footerContainer hr {
  width: 80%;
  height: 1px;
  color: #fff;
}
.FooterEnd {
  margin-top: 0px;
  width: 100%;
  text-align: center;
}
.FooterEnd p {
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  color: #fff;
}
@media (max-width: 991px) {
  .FooterInput,
  .errMsgP {
    width: 80%;
  }
  .footerlogo {
    width: 40%;
  }
  .FooterSocialIcon {
    width: 36%;
  }
}
@media (max-width: 600px) {
  .footerlogo {
    width: 70%;
  }
  .FooterSocialIcon {
    width: 45%;
  }
  .FooterInput,
  .errMsgP {
    width: 100%;
  }
  .FooterInput button {
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .FooterSocialIcon {
    width: 65%;
  }
  .footerlogo {
    width: 90%;
  }
  .FooterInput button {
    font-size: 11px;
    line-height: 1;
  }
}
@media (max-width: 320px) {
  .FooterSocialIcon {
    width: 90%;
  }
}
