.dashBoardContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 20px 0px 0px !important;
  width: 100%;
}

.allSurveyAnalyticsPage {
  padding-top: 70px;
  margin-bottom: 50px;
}

.dashBoardSearch {
  max-width: 100%;
  background: #f5f5f5;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 8px !important;
}

.filterContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: #f5f5f5;
  border: 1px solid #aaaaaa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 15px;
  width: 250px;
  margin: 0 0 0 auto;
}

.filterContainer select {
  width: 100%;
  height: 41px;
  background: #f5f5f500;
  border: none;
}

.filterContainer svg {
  color: #7b7b7b;
}

.overViewContainer {
  padding-top: 0px;
  padding-bottom: 0px;
}

.surveyOverview {
  margin-top: 30px;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  padding: 22px;
}

.surveyOverview hr {
  color: #aaaaaa;
  height: 1px;
  opacity: 1;
}

.surveyOverview h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.surveyBlock {
  margin: 25px 0px 0px;
}

.surveyBlock p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #1d1d1d;
  margin-bottom: 12px;
}

.surveyCol {
  position: relative;
}

.surveyCol:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #aaa;
  position: absolute;
  top: 0;
  right: 0;
}

.surveyCol:nth-child(4):after {
  display: none;
}

.firstCardInner {
  text-align: center;
  background: #f0f0f0;
  border: 1px dashed #7b7b7b;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 100%;
  row-gap: 30px;
}

.firstCardInner img {
  max-width: 25%;
}

.cardInner p,
.firstCardInner p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c4043;
}

.firstCardInner,
.cardInner,
.newSurvey {
  width: 100%;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding-top: 30px !important;
}

.cardImgContainer {
  min-height: 186px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.newSurvey {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  min-width: 200px;
}

.cardDetails {
  padding: 16px;
}

.cardDetails .cardTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.cardTime p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7b7b7b;
}

.cardTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardTime svg {
  fill: #7b7b7b;
  width: 20px;
}

.analyticsSingleCard .cardTimeBlock {
  flex-direction: column !important;
}

.allAnalytics {
  width: 100% !important;
}

@media only screen and (max-width: 950px) {
  .filterContainer {
    width: 100%;
  }

  .surveyCol:after {
    display: none;
  }

  .dashBoardContainer,
  .overViewContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .allAnalytics {
    width: 90% !important;
    margin: auto;
    padding: 20px 0px;
  }
  .dashBoardContainer {
    width: 100% !important;
    padding: 0;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 550px) {
  .cardContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .overViewContainer,
  .cardContainer {
    padding-left: 25px;
    padding-right: 25px;
  }

  .firstSurvey {
    min-height: 259px;
  }

  .surveyBlock {
    width: 100%;
  }
}

.allAnalytics {
  padding-top: 0px;
}

.noAnalyticsSurvey {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.noAnalyticsSurvey img {
  max-width: 550px;
}

.noAnalyticsSurvey h4 {
  margin: 30px 0px;
}

.noAnalyticsSurvey button {
  max-width: 200px;
  padding: 5px 0px;
}

.pageTitle {
  font-size: 30px;
}
