.dashBoardContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dashBoardSearch {
  max-width: 100%;
  background: #f5f5f5;
  border: 1px solid #fe7a46;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 8px !important;
}
.helpBtn {
  margin-right: 10px;
  border: 2px solid  #000 !important;
  background-color: #fff !important;
  color: #000 !important;
}
.helpBtn:hover {
  margin-right: 10px;
  border: 2px solid  #000 !important;
  background-color: #000 !important;
  color: #fff !important;
}
.filterContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: #f5f5f5;
  border: 1px solid #aaaaaa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 15px;

  width: 100%;
  margin: 0 0 0 auto;
}
.viewSurveyHeadCol2 button {
  width: auto;
  height: auto;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewSurveyHeadCol2 button svg {
  font-size: 18px;
}

.viewSurveyHeadCol2 button:hover {
  background: #fe7a46;
}

.viewSurveyHeadCol2 button svg {
  margin-left: 0 !important;
}

.viewSurveyHeadCol2 {
  column-gap: 10px;
}

.viewSurveyActionBtn {
  justify-content: flex-end;
}

.cardBtn {
  z-index: 10;
}
.cardLink {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.filterContainer select {
  width: 100%;
  height: 41px;
  background: #f5f5f500;
  border: none;
}

.filterContainer select:focus-visible {
  outline: none;
}

.filterContainer svg {
  color: #7b7b7b;
}

.overViewContainer {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
}

.overViewContainer .dashBoardTitle {
  font-size: 30px !important;
}

.surveyOverview {
  margin-top: 30px;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  padding: 10px;
}

.surveyOverview hr {
  color: #aaaaaa;
  height: 1px;
  opacity: 1;
}

.surveyOverview h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.surveyBlock {
  margin: 10px 0px 0px !important;
  width: 100% !important;
}

.surveyBlock p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #959595;
  margin-bottom: 12px;
}

.surveyCol {
  position: relative;
}

.surveyCol h3 {
  color: #000;
  font-weight: 600;
}

.surveyCol:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #aaa;
  position: absolute;
  top: 0;
  right: 0;
}

.surveyCol:nth-child(3):after {
  display: none;
}

.firstCardInner {
  text-align: center;
  background: #f0f0f0;
  border: 1px dashed #7b7b7b;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 100%;
  row-gap: 30px;
  cursor: pointer;
}

.singleCard {
  min-height: 189px;
}

.firstCardInner img {
  max-width: 25%;
}

.cardInner p,
.firstCardInner p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c4043;
}

.firstCardInner,
.cardInner,
.newSurvey {
  width: 100%;
}

.newSurvey {
  position: relative;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding-top: 30px !important;
}

.cardImgContainer {
  min-height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.newSurvey {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
.newSurvey:hover {
  box-shadow: 0px 2px 4px #e24c2d4d;
}
.cardDetails {
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardDetails h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.cardDetails .lastUpdateText {
  font-size: 11px;
  font-weight: bold;
  color: #000;
}

.cardDetails .cardTitle {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
}

.cardTime p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7b7b7b;
}

.cardTime {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.cardDescriptionContainer {
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.analyticsSurveyBlock .cardDescriptionContainer {
  min-height: unset;
}

.newSurvey {
  transform: scale(1);
  transition: all 0.5s ease-in !important;
}

.newSurvey:hover {
  transform: scale(1.05) !important;
}

.analyticsSurveyBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alertTitle {
  padding: 24px !important;
}

.alertTitle h2 {
  font-weight: 800 !important;
  font-size: 24px;
}

.deleteSurveyModal input {
  margin-top: 20px;
}

.deleteBtnContainer {
  margin-right: 22px;
  margin-bottom: 24px;
}

.analyticsBtn {
  border-top: 1px solid #aaaaaa;

  padding: 10px 0px;

  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.analyticsBtn button {
  width: 100%;
  border: none;
  background: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #40547b;
}

.noSurveyContainer {
  text-align: center;
  padding-top: 50px;
}

.noSurveyContainer h3 {
  /* font-size: 30px;
  color: #959595; */
  font-size: 24px;
  color: #121212;
  margin-bottom: 10px;
}

.noSurveyContainer button {
  width: auto;
  padding: 7px 20px;
  margin-top: 20px;
  font-size: 20px;
}

.noSurveyCol2 {
  align-self: center;
}

.moreOptionContainer button {
  padding: 0;
}

.cardDescription h4 {
  color: #40547b;
}

.popuarSurveyContainer {
  padding-bottom: 0px;
  padding-top: 30px !important;
}

.popuarSurveyContainer .cardDescriptionContainer {
  min-height: unset;
}

.popuarSurveyContainer .cardTime {
  display: none;
}

.popuarSurveyContainer .cardDetails {
  padding: 5px 16px;
}

.dashBoardTitle {
  font-size: 25px !important;
}

.disabledSurveyContainer .enableBtnContainer {
  z-index: 1;
}

.enableBtnContainer button {
  padding: 3px 0px;
  font-size: 16px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
}

.disabledSurveyContainer .newSurvey:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #7a7a7a29;
  position: absolute;
  top: 0;
  z-index: 0;
}

.disabledSurveyContainer .cardLink {
  display: none;
}

.disabledSurveyContainer button:hover {
  background-color: #fe7a46;
}

.loginCol2 .surveyInfoBox {
  border-radius: 8px;
  background-color: #fe7a4669;
  padding: 8px 11px;
  text-align: center;
}

.analyticsBtn:hover {
  background-color: #fe7e44;
}

.analyticsBtn:hover button {
  background: transparent !important;
  color: #fff;
}

.firstCardContainer {
  border: 2px solid #fe7a46;
  padding: 2px;
}

.surveyInfoBox {
  text-align: center;

  border-radius: 4px;
  padding: 10px 0;
  border: 2px solid #fe7a46;
}

.surveyInfoBox p {
  color: #4b4b4b;
  font-weight: 500;
  font-size: 14px;
}

.surveyInfoBox h4 {
  color: #121212;
  font-weight: 700;
  font-size: 22px;
  margin: 8px 0 0;
}

.disableContainer {
  margin-top: 30px;
}

.createSurveyBtnContainer {
  display: flex;
  justify-content: flex-end;
}

.createSurveyBtnContainer button {
  width: auto;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 18px;
  border: 2px solid #40547b;
  font-weight: 600;
}

.createSurveyBtnContainer button:hover {
  background-color: #fff;
  color: #40547b;
}

.informationCardContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.infoBox {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  padding: 10px 0px;
}

.infoBox p {
  font-size: 18px;
  margin-bottom: 8px;
  color: #40547b;
  font-weight: 700;
}

.infoBox h4 {
  color: #121212;
  font-size: 22px;
  margin: 0;
}

.infoBox1 {
  border-top: 4px solid #00c247;
}

.infoBox2 {
  border-top: 4px solid #fc644c;
}

.infoBox3 {
  border-top: 4px solid #bdbdbd;
}

.infoBox4 {
  border-top: 4px solid #40547b;
}

.mySurveyPage {
  padding-top: 70px;
}

@media only screen and (max-width: 1200px) {
  .viewSurveyActionBtn {
    row-gap: 5px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 950px) {
  .filterContainer {
    width: 100%;
  }

  .surveyCol:after {
    display: none;
  }

  .dashBoardContainer,
  .overViewContainer,
  .cardContainer {
    padding-left: 50px;
    padding-right: 50px;
  }

  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .viewSurveyHeadCol2 {
    align-self: flex-start;
    max-width: 100%;
    flex-basis: 100%;
  }

  .MuiGrid-root.MuiGrid-container.viewSurveyHead {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 550px) {
  .cardContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .noSurveyContainer h3 {
    font-size: 26px;
    margin-top: 30px;
  }

  .noSurveyContainer button {
    padding: 7px 12px;
  }

  .dashBoardContainer,
  .overViewContainer,
  .cardContainer {
    padding-left: 25px;
    padding-right: 25px;
  }

  .firstSurvey {
    min-height: 259px;
  }

  .surveyBlock {
    width: 100%;
  }
}
