.maxPlanLimitContainer {
}

.maxPlanPageInner {
  max-width: 700px;
  margin: 90px auto 0;
  padding-block: 20px;
}

.maxPlanPageInner img {
  max-width: 400px;
}

.maxPlanPageInner h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  text-align: center;

  color: #40547b;
}

.maxPlanPageInner p {
  font-weight: 400;
  font-size: 25px;
  line-height: 43px;
  text-align: center;
  color: #14213d;
  margin-top: 15px;
}

.maxPlanPageInner button {
  width: 100%;
  max-width: 200px;
  background: #fc6c49;
  border-radius: 8px;
  color: #fff;
  padding-block: 7px;
  border: 2px solid #fc6c49;
  margin-top: 15px;
}

.maxPlanPageInner button:hover {
  background-color: #fff;
  color: #fc6c49;
}
