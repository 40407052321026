.forgotPassContainer {
  padding: 200px 0 100px;
}

.envelopeImage {
  display: flex;
  justify-content: center;
}

.detailText {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  text-align: center;

  color: #4f4f4f;
}

.sendText {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 0px 6px;
}

.returnText {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #002ed0;
}

.arrow {
  width: 28.17px;
  height: 18px;
  left: 1079.45px;
  top: 688.66px;
}
.emailText {
  width: 100%;
}

.emailText input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: black !important;
  opacity: 5;
}
.emailField fieldset {
  top: 0px !important;
}
