.register .emailLogin,
.register .passwordLogin {
  width: 100%;
  border-radius: 4px !important;
  border: 2px solid #f69033;
}

.selectCompany {
  width: 100% !important;
  border-radius: 4px !important;
  border: 2px solid #f69033;
  margin: 4px !important;
}

.loginButton {
  font-family: Mulish;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  background: #1976d2;
  color: white !important;
  border-radius: 4px;
  padding: 4px 10px;
}

.successMsg {
  background: #8affe4;
  padding: 6px;
  border-radius: 4px;
  font-size: 16px;
  color: #02885a;
  margin-top: 8px;
}

.register .primary {
  background-color: #000;
  color: #fff;
  width: 100%;
  border-radius: 4px !important;
  border: 2px solid #000;
  margin: 15px auto !important;
}

.register .secondary {
  background-color: #fff !important;
  color: #000 !important;
  width: fit-content;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  border: 2px solid #00000020;
  margin: 30px auto !important;
}

.loginText {
  font-family: Mulish;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  text-decoration: none;
}

.signupTextBlue {
  font-family: Mulish;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: rgb(80, 68, 166) !important;
  text-decoration: none;
}
.register .googleIcon {
  width: 6%;
  border-radius: 3px;
}

.signUpFormContainer input:focus {
  outline: #f69033;
  border-color: #f69033;
  background: #fff !important;
}
.optVerify {
  display: flex;
  justify-content: center;
  margin: auto;
}
.resendOtp {
  color: rgb(40, 40, 230);
  cursor: pointer;
}
.otpInputsFields {
  width: 60px;
}
.otpInputsFields input {
  width: 3em !important;
  height: 2.5em !important;
}
@media only screen and (max-width: 550px) {
  .otpInputsFields {
    width: 34px;
  }
  .otpInputsFields input {
    width: 2em !important;
  }
  .verificationOtpPara {
    font-size: 9px !important;
  }
}
