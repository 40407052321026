.passwordHelpContainer {
  padding-top: 130px;
  padding-bottom: 130px;
  text-align: center;
}

.passwordHelpContainer h2 {
  font-size: 45px;
  margin-bottom: 20px;
}

.passwordHelpContainer img {
  max-width: 25%;
}

.passwordHelpContainer p {
  max-width: 60%;
  margin: 0 auto;
}

.resendEmail {
  font-size: 2rem;
}
