@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;800&family=Raleway:wght@500;600;700;800;900&display=swap");

a {
  text-decoration: none !important;
}

.ct-toast-success {
  background-color: #2c9301 !important;
  color: #fff !important;
  padding-right: 90px !important;
}

/* .cancelButton {
  border: 2px solid #fe7248 !important;
  padding: 4px 8px !important;
} */

.ct-toast-error {
  background-color: #ff2f4d !important;
  color: #fff !important;
  padding-right: 90px !important;
}

.passwordContainerLogin .svgEyeContainer {
  top: -8px !important;
}

.signUpPassword .svgEyeContainer {
  top: -18px !important;
}
.paymentCheckoutForm button svg {
  color: #fff !important;
}
