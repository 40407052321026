.deleteSurveyModal .MuiDialog-paper {
    padding: 15px 50px !important;
    border-radius: 8px;
}

#alert-dialog-title-question h2 {
    font-weight: 700;
}

.deleteBtnBlock {
    justify-content: center !important;
}

.deleteBtnBlock button:last-child {
    background-color: #fe7248 !important;
    border: 2px solid #fe7248 !important;
}

.deleteBtnBlock button:last-child:hover {
    background: #fff !important;
    color: #fe7248 !important;
}

.sendImgContainer {
    text-align: center;
    position: relative;
}

.sendImgContainer .tailImg {
    position: absolute;
    bottom: -13px;
    right: 276px;
    max-width: 50px;
}

 .sendGif {
    max-width: 150px;
}