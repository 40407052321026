.welcomeScreenContainer {
  padding: 110px 130px 60px;
}

.welcomeCol1 {
  align-self: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.welcomeCol1 .majorHeading {
  font-size: 28px;
  text-align: center !important;
  color: #00c85c;
  font-weight: 700;
}
.welcomeScreenHeading h1 {
  font-size: calc(2rem + 1vw);
}
.welcomeScreenHeading img {
  display: inline-block;
  max-width: 150px;
}

.welcomeCol1 h5 {
  color: #fc644c;
  font-size: 30px;
  margin-bottom: 25px;
}

.welcomeCol1 button {
  margin-top: 30px;
  max-width: 290px;
  padding: 5px 0px;
}

.welcomeCol2 {
  align-self: center;
}

@media only screen and (max-width: 950px) {
  .welcomeCol1 {
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 550px) {
  .welcomeScreenContainer {
    padding: 100px 20px;
  }

  .welcomeScreenHeading img {
    display: none;
  }

  .btnContainer button {
    max-width: 200px;
    margin-top: 40px;
  }
}
