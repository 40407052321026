.navLink span {
  font-weight: 700;
}

.loginSignUp {
  color: #000;
  align-items: center;
}
.navLink {
  color: #000;
  font-weight: 700;
}
.loginPageMain
  .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}

.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary .navLink,
.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary a {
  color: #fff;
}

.MuiAppBar-positionAbsolute.MuiAppBar-colorPrimary .navLink:hover{ 
   color: #fff;
}
.navLink span {
  position: relative;
}

.navLink span:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -12px;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.navLink:hover span:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
